// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-easy-green-salad-js": () => import("./../src/pages/easy-green-salad.js" /* webpackChunkName: "component---src-pages-easy-green-salad-js" */),
  "component---src-pages-golden-milk-js": () => import("./../src/pages/golden-milk.js" /* webpackChunkName: "component---src-pages-golden-milk-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-post-archive-js": () => import("./../src/pages/post-archive.js" /* webpackChunkName: "component---src-pages-post-archive-js" */),
  "component---src-pages-roasted-chicken-js": () => import("./../src/pages/roasted-chicken.js" /* webpackChunkName: "component---src-pages-roasted-chicken-js" */),
  "component---src-pages-single-post-js": () => import("./../src/pages/single-post.js" /* webpackChunkName: "component---src-pages-single-post-js" */),
  "component---src-pages-user-profile-js": () => import("./../src/pages/user-profile.js" /* webpackChunkName: "component---src-pages-user-profile-js" */)
}

